<template>
  <div class="ml-question-search pt-3">
    <div class="container">
      <div class="row">
        <div class="col-sm-4 col-lg-2 offset-lg-1">
          <div class="list-filter d-none d-lg-block">
            <div class="row">
              <div class="col-4 col-lg-12 search-list">
                <h5>Môn</h5>
                <div class="search-item">
                  <b-form-checkbox v-for="item in listCategory" v-model="query.category_id" :key="item.id" :value="item.id" name="subjects" class="search-checkbox">
                    <span class="text-label pl-2 pointer">{{ item.name }}</span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-4 col-lg-12 search-list">
                <h5>Lớp</h5>
                <div class="search-item">
                  <b-form-checkbox v-for="item in listClass" v-model="query.class_id" :key="item.id" :value="item.id" name="classes" class="search-checkbox">
                    <span class="text-label pl-2 pointer">{{ item.name }}</span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-4 col-lg-12 search-list">
                <h5 for="">Khác</h5>
                <b-form-checkbox v-model="query.type" :value="1" name="typeItem" class="search-checkbox">
                  <span class="text-label pl-2 pointer">Câu hỏi</span>
                </b-form-checkbox>
                <b-form-checkbox v-model="query.type" :value="2" name="typeItem" class="search-checkbox">
                  <span class="text-label pl-2 pointer">Câu trả lời</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-8 col-lg-7">
          <FormSearch placeholder="Nhập từ khóa" class="mb-3" @enter="searchData" :value="query.keyword"/>
          <div class="list-question">
            <div class="d-flex">
              <div class="total-result text-primary" v-if="keyword">
                {{ query.total }} kết quả tìm kiếm cho "<b>{{ keyword }}</b>"
              </div>
              <div class="action ml-auto pointer" :class="{'active': showFilter}" @click="openFilter">
                <span class="mr-2">Lọc</span>
                <span v-html="require('~/assets/mlearn/icons/home/filter.svg?raw')"/>
              </div>
            </div>
            <div class="content-question">
              <SearchItem v-for="item in listData" :key="item.id" :item="item"/>
              <NoData v-if="!listData.length"/>
              <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="getSearchResult"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MobileFilter title="Lọc câu hỏi" :show-filter="showFilter" @applyFilter="applyFilter" @closeFilter="closeFilter">
      <div class="block-data">
        <div class="title text-uppercase">Môn</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.category_id.length}" @click="setQuery('category_id', [])">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listCategory" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.category_id.includes(item.id)}" @click="setQuery('category_id', item.id)">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="block-data">
        <div class="title text-uppercase">Lớp</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.class_id.length}" @click="setQuery('class_id', [])">Tất cả</div>
          </div>
          <div class="col-4 item" v-for="item in listClass" :key="'filter' + item.id">
            <div class="block-item" :class="{'active': query.class_id.includes(item.id)}" @click="setQuery('class_id', item.id)">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="block-data">
        <div class="title text-uppercase">Khác</div>
        <div class="row list-item">
          <div class="col-4 item">
            <div class="block-item" :class="{'active': !query.type.length}" @click="setQuery('type', [])">Tất cả</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.type.includes(1)}" @click="setQuery('type', 1)">Câu hỏi</div>
          </div>
          <div class="col-4 item">
            <div class="block-item" :class="{'active': query.type.includes(2)}" @click="setQuery('type', 2)">Câu trả lời</div>
          </div>
        </div>
      </div>
    </MobileFilter>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Pagination from '~/components/mlearn/pagination/index'
import SearchItem from '~/components/mlearn/search/item.vue'
import MobileFilter from '~/components/mlearn/mobile/filter.vue'

// const categoryResource = new Resource('category/subject')
// const classResource = new Resource('category/class')
const searchResource = new Resource('qa/search')

export default {
  components: {
    Pagination,
    MobileFilter,
    FormSearch,
    SearchItem
  },
  asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query: qr,
    req,
    res,
    redirect,
    error
  }) {
    const keyword = qr.keyword || ''
    return {
      listCategory: [],
      listClass: [],
      keyword,
      query: {
        category_id: [],
        class_id: [],
        keyword,
        limit: 20,
        offset: 0,
        total: 0,
        page: 1,
        type: []
      },
      listData: [],
      loading: false,
      showFilter: false
    }
  },
  watch: {
    'query.category_id' () {
      !this.showFilter && this.handleFilter()
    },
    'query.class_id' () {
      !this.showFilter && this.handleFilter()
    },
    'query.type' () {
      !this.showFilter && this.handleFilter()
    },
    $route: {
      deep: true,
      handler () {
        const keyword = this.$route.query.keyword
        this.keyword = keyword
        this.query.keyword = keyword
        this.getSearchResult()
      }
    }
  },
  created () {
    this.getListCategory()
    this.getListClass()
    this.getSearchResult()
  },
  methods: {
    getListClass () {
      const {data} = this.$store.getters['context/classes']
      this.listClass = data
    },
    getListCategory (slug = null) {
      const {data} = this.$store.getters['context/subjects']
      this.listCategory = data
    },
    async getSearchResult (page = 1) {
      if (this.loading) {
        return
      }
      this.query.page = page
      // if (!this.query.keyword.trim()) {
      //   return false
      // }
      this.query.keyword = this.query.keyword.trim()
      if (this.query.keyword.length > 100) {
        this.query.keyword = this.query.keyword.substring(0, 100)
      }
      this.query.offset = this.query.limit * (this.query.page - 1)
      this.loading = true
      const {
        data,
        total
      } = await searchResource.store(this.query)
      this.listData = data
      this.query.total = total
      this.loading = false
      this.keyword = this.query.keyword
    },
    handleFilter () {
      this.getSearchResult()
    },
    redirectDetailQuestion (questionId) {
      this.$router.push({
        path: '/cau-hoi/' + questionId
      })
    },
    redirectAnswer (questionId) {
      this.$router.push({
        path: '/tra-loi/' + questionId
      })
    },
    setQuery (field, value) {
      if (typeof value === 'string' || typeof value === 'number') {
        this.query[field] = [...this.query[field], value]
      } else {
        this.query[field] = value
      }
    },
    openFilter () {
      this.showFilter = true
    },
    closeFilter () {
      this.showFilter = false
    },
    applyFilter () {
      this.closeFilter()
      this.getSearchResult()
    },
    searchData (keyword = '') {
      this.query.keyword = keyword
      this.getSearchResult()
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-question-search {
  .list-filter {
    .search-list {
      .search-item {
        .search-checkbox {
          margin-bottom: 10px;

          .custom-control-label {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .list-question {
    .total-result {
      font-weight: 500;
      font-size: 16px;
    }

    .content-question {
      .item-question {
        padding: 24px;
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
        border-radius: 8px;
        margin-top: 15px;
        border-radius: 8px;
      }
    }
  }
}
</style>
