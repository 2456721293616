<template>
  <section class="my-lib-inner-section">
    <div class="container">
      <p class="page-title font-weight-bold">Thư viện của tôi</p>
      <div class="my-lib-tabs bg-white">
        <div class="tabs-controller">
          <ul class="nav nav-pills mb-0 list-tabs-controller" role="tablist">
            <li class="nav-item">
              <a class="nav-link mr-3 mr-lg-4 mr-xl-5 py-2 py-xl-3 px-1 position-relative bg-transparent" :class="{'active': tabActive === 0}" @click="tabActive = 0" id="pills-tab-topic" data-toggle="pill" href="#tab-content-topic" role="tab" aria-controls="tab-content-topic" aria-selected="true">Khóa học</a>
            </li>
            <li class="nav-item">
              <a class="nav-link mr-3 mr-lg-4 mr-xl-5 py-2 py-xl-3 px-1 position-relative bg-transparent" :class="{'active': tabActive === 1}" @click="tabActive = 1" id="pills-tab-document" data-toggle="pill" href="#tab-content-document" role="tab" aria-controls="tab-content-document" aria-selected="false">Tài liệu</a>
            </li>
            <li class="nav-item">
              <a class="nav-link py-2 py-xl-3 px-1 position-relative bg-transparent" :class="{'active': tabActive === 2}" @click="tabActive = 2" id="pills-tab-quiz" data-toggle="pill" href="#tab-content-quiz" role="tab" aria-controls="tab-content-quiz" aria-selected="false">Trắc nghiệm</a>
            </li>
            <li class="nav-item d-none d-lg-flex align-items-center ml-auto" v-if="tabActive > 0">
              <div class="dropdown tab-types-dropdown ml-2 ml-lg-3 bg-white" v-if="myPlaylistDoc.length && tabActive === 1">
                <p class="btn d-flex align-items-center justify-content-between dropdown-toggle mb-0 selected-data color-darkgrey" type="button" id="filterDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ placeholder }}
                  <img src="~/assets/icons/library/chevron-dropdown.svg" alt="">
                </p>
                <div class="dropdown-menu select-items bg-white" aria-labelledby="filterDropdown">
                  <div class="dropdown-item p-2 pointer" v-for="(it, ind) in myPlaylistDoc" :key="ind" :class="{'on-active': idActive === it.id}" @click="setActive(it)">{{ it.name }} ({{ it.number_file }})</div>
                </div>
              </div>
              <div class="dropdown tab-types-dropdown ml-2 ml-xl-3 bg-white" v-if="myPlaylistExam.length && tabActive === 2">
                <p class="btn d-flex align-items-center justify-content-between dropdown-toggle mb-0 selected-data color-darkgrey" type="button" id="filterDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ placeholder }}
                  <img src="~/assets/icons/library/chevron-dropdown.svg" alt="">
                </p>
                <div class="dropdown-menu select-items bg-white" aria-labelledby="filterDropdown">
                  <div class="dropdown-item p-2 pointer" v-for="(it, ind) in myPlaylistExam" :key="ind" :class="{'on-active': idActive === it.id}" @click="setActive(it)">{{ it.name }} ({{ it.number_exam }})</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade" :class="{'show active': tabActive === 0}" id="tab-content-topic" role="tabpanel" aria-labelledby="pills-tab-topic">
              <MyTopic v-if="tabActive === 0"/>
            </div>
            <div class="tab-pane fade tab-docs" :class="{'show active': tabActive === 1}" id="tab-content-document" role="tabpanel" aria-labelledby="pills-tab-document">
              <MyDocument v-if="tabActive === 1" @delete="loadPlaylistDoc" @updateState="updateState"/>
              <NoData v-if="!myPlaylistDoc.length" text="Bạn chưa lưu danh sách tài liệu nào" />
            </div>
            <div class="tab-pane fade tab-mcq" :class="{'show active': tabActive === 2}" id="tab-content-quiz" role="tabpanel" aria-labelledby="pills-tab-quiz">
              <MyQuiz v-if="tabActive === 2" @delete="loadPlaylistQuiz" @updateState="updateState" />
              <NoData v-if="!myPlaylistExam.length" text="Bạn chưa lưu danh sách trắc nghiệm nào" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import MyTopic from '~/components/library-v2/my-library/topic.vue'
import MyDocument from '~/components/library-v2/my-library/document.vue'
import MyQuiz from '~/components/library-v2/my-library/quiz.vue'
import NoData from '~/components/nodata/new.vue'
import Resource from '~/common/api/resource'
const playlistAllResource = new Resource('library/playlist/all')
const playlistResource = new Resource('library/playlist')
export default {
  components: {
    MyTopic,
    MyDocument,
    NoData,
    MyQuiz
  },
  layout: 'thu-vien',
  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const tabActive = 0
    const token = store.state.token
    return {
      token,
      tabActive,
      placeholder: '',
      idActive: ''
    }
  },
  computed: {
    ...mapGetters([
      'myPlaylistDoc',
      'myPlaylistExam'
    ])
  },
  watch: {
    tabActive (newValue, oldValue) {
      switch (newValue) {
        case 1: {
          const item = this.myPlaylistDoc.length && this.myPlaylistDoc[0]
          this.setActive(item)
          break
        }
        case 2: {
          const item = this.myPlaylistExam.length && this.myPlaylistExam[0]
          this.setActive(item)
          break
        }
        default:
          break
      }
    }
  },
  created () {
    this.loadPlaylistDoc()
    this.loadPlaylistQuiz()
  },
  methods: {
    loadPlaylistDoc () {
      playlistAllResource.list({ limit: 100, type: 'doc' }, this.token)
        .then((res) => {
          this.$store.commit('context/SET_MY_PLAYLIST_DOC', res.data)
        })
      playlistResource
        .list({ limit: 100, type: 'doc' }, this.token)
        .then((res) => {
          this.$store.commit('context/SET_PLAYLIST_DOC', res.data)
        })
    },
    loadPlaylistQuiz () {
      playlistAllResource.list({ limit: 100, type: 'exam' }, this.token)
        .then((res) => {
          this.$store.commit('context/SET_MY_PLAYLIST_EXAM', res.data)
        })
      playlistResource
        .list({ limit: 100, type: 'exam' }, this.token)
        .then((res) => {
          this.$store.commit('context/SET_PLAYLIST_EXAM', res.data)
        })
    },
    setActive (item) {
      if (!item) {
        this.placeholder = 'Danh sách playlist'
        return
      }
      this.idActive = item.id
      this.placeholder = `${item.name} (${item.number_file || item.number_exam})`
      $('#expand-item-content-' + item.id).collapse('show')
    },
    updateState () {
      switch (this.tabActive) {
        case 1: {
          const item = this.myPlaylistDoc.length && this.myPlaylistDoc[0]
          if (!item) {
            this.placeholder = 'Danh sách playlist'
            return
          }
          this.placeholder = `${item.name} (${item.number_file || item.number_exam})`
          break
        }
        case 2: {
          const item = this.myPlaylistExam.length && this.myPlaylistExam[0]
          if (!item) {
            this.placeholder = 'Danh sách playlist'
            return
          }
          this.placeholder = `${item.name} (${item.number_file || item.number_exam})`
          break
        }
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-lib-inner-section{
  padding-top: 2.25rem;
  padding-bottom: 9rem;
  @media (max-width: 1366px) {
    padding-top: 1.75rem;
    padding-bottom: 4rem;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    padding-top: 1.5rem;
    padding-bottom: 4rem;
  }
  .page-title {
    line-height: 1.3;
    color: #262f3e;
    font-size: 1.75rem;
    margin-bottom: 1.75rem;
    @media (max-width: 1366px) {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }
  .my-lib-tabs {
    border-radius: .5rem;
    padding: 2rem 1.3rem 3rem;
    @media (max-width: 1366px) {
      padding: 1rem 1rem 2rem;
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
      padding: 1rem .75rem;
    }
    .tabs-controller {
      .list-tabs-controller {
        border-bottom: 1px solid #e6e6e6;
        .nav-item{
          .nav-link {
            color: #6f6f6f;
            font-size: 1.25rem;
            @media (max-width: 1366px) {
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 992px) {
            }
            @media (max-width: 768px) {
            }
            @media (max-width: 576px) {
              font-size: 1rem;
            }
            &::after {
              content: '';
              width: 100%;
              height: .25rem;
              border-radius: 2px;
              position: absolute;
              bottom: 0;
              left: 0;
              background: transparent;
            }
            &.active {
              font-weight: 500;
              color: #4086ff;
              &::after {
                background-color: #4086ff;
              }
            }
          }
          .tab-types-dropdown {
            border: 1px solid #e6e6e6;
            border-radius: .3rem;
            min-width: 11rem;
            @media (max-width: 1366px) {
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 992px) {
            }
            @media (max-width: 768px) {
            }
            @media (max-width: 576px) {
            }
            .selected-data {
              line-height: 1.3;
              height: 2.5rem;
              @media (max-width: 1366px) {
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 992px) {
              }
              @media (max-width: 768px) {
              }
              @media (max-width: 576px) {
                height: 2rem;
              }
            }
            .select-items {
              max-width: 100%;
              min-width: 11.125rem;
              border: 1px solid #e6e6e6;
              border-radius: .5rem;
              @media (max-width: 1366px) {
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 992px) {
              }
              @media (max-width: 768px) {
              }
              @media (max-width: 576px) {
              }
              .dropdown-item {
                max-width: 100%;
                white-space: break-spaces;
                line-height: 1.3;
                color: #a5a5a5;
                @media (max-width: 1366px) {
                }
                @media (max-width: 1200px) {
                }
                @media (max-width: 992px) {
                }
                @media (max-width: 768px) {
                }
                @media (max-width: 576px) {
                }
                &.on-active {
                  font-weight: 500;
                  color: #333333;
                }
              }
            }
          }
        }
      }
      .tab-content{
        padding-top: 1.7rem;
        @media (max-width: 1366px) {
          padding-top: 1.25rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
          padding-top: 1rem;
        }
      }
    }
  }
}
</style>
