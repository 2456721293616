<template>
  <div class="doc-detail-inner-section" v-if="item">
    <div class="container">
      <ol class="bg-transparent breadcrumb p-0">
        <li class="breadcrumb-item">
          <nuxt-link to="/tai-lieu">Tài liệu</nuxt-link>
        </li>
        <li class="breadcrumb-item">{{ item.class_name }}</li>
        <li class="breadcrumb-item">{{ item.category_name }}</li>
        <li class="breadcrumb-item" aria-current="page">{{ item.name }}</li>
      </ol>
      <p class="color-darkgrey doc-detail-content font-weight-bold">{{ item.name }}</p>
      <div class="row video-detail-row">
        <div :class="relationsTopic.length || relations.length ? 'col-12 col-lg-9' : 'col-12'">
          <div v-if="!user" class="video-private lib-no-login" style="--aspect-ratio: 58.37%;">
            <div>
              <img v-if="item.thumbnail" :src="item.thumbnail" alt="video-private" class="no-login-thumb">
              <img v-else src="~/assets/images/library/default.jpg" alt="video-private" class="no-login-thumb">
            </div>
            <div class="v-login">
              <div class="v-login-text">Bạn sẽ không thể xem đầy đủ nội dung nếu chưa đăng nhập!</div>
              <div class="v-login-btn" @click="$bvModal.show('modal-login')">Đăng nhập ngay</div>
            </div>
          </div>
          <div v-else class="library-preview-file">
            <client-only>
              <!--              <ViewPdf :link="item.url" height="100%"/>-->
              <ViewPdfV2 :link="item.url" height="100%"/>
            </client-only>
          </div>
        </div>
        <div v-if="relationsTopic.length || relations.length" id="document-relation-right" class="col-12 col-lg-3 mt-3 mt-lg-0 sticky-top scrollbar overflow-auto bg-transparent">
          <div class="document-relation bg-white mb-3 mb-lg-4" v-if="relationsTopic.length">
            <div class="document-relation--title text-center fw-700 color-darkgrey text-uppercase">Tài liệu cùng Khóa học</div>
            <div class="document-relation--list text-justify fw-400 color-darkgrey">
              <div class="document-relation--list--item pointer pb-2 mb-2 align-middle display-2-line d-flex" v-for="(item,ind) in relationsTopic" :key="ind" @click="checkBeforeRedirect(item)">
                <div class="limit-line-2">{{ item.name }}</div>
                <img v-if="!item.is_free" src="~/assets/icons/library/icon-private.svg" class="icon-private" alt="">
              </div>
            </div>
          </div>
          <div class="document-relation bg-white" v-if="relations.length">
            <div class="document-relation--title text-center fw-700 color-darkgrey text-uppercase">Tài liệu liên quan</div>
            <div class="document-relation--list text-justify fw-400 color-darkgrey">
              <div class="document-relation--list--item pointer pb-2 mb-2 align-middle display-2-line d-flex" v-for="(item,ind) in relations" :key="'relation-'+ind" @click="checkBeforeRedirect(item)">
                <div class="limit-line-2">{{ item.name }}</div>
                <img v-if="!item.is_free" src="~/assets/icons/library/icon-private.svg" class="icon-private" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Resource from '~/common/api/resource'

const relationResource = new Resource('library/file/related')
const relationTopicResource = new Resource('library/file/related-topic')
const documentResource = new Resource('library/file/getBySlug')
const playlistResource = new Resource('library/playlist')
export default {
  layout: 'mlearn',
  async asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error
  }) {
    const token = store.state.token
    const data = await documentResource.list({slug: params.slug}, token)
      .catch((err) => {
        if (err.response?.data.status === 404) {
          return redirect('/')
        }
        return error({statusCode: err.response?.data.status})
      })
    if (!data) {
      return redirect('/')
    }
    const imageDefault = process.env.NUXT_ENV_WEB_URL + '/library/default.jpg'
    const item = data.data
    const metaSeo = {
      title: item.meta_title || item.name,
      image: item.meta_image || item.thumbnail || imageDefault,
      description: item.meta_description || item.name,
      keyword: item.meta_keyword || ''
    }
    const structuredData = {}
    return {
      metaSeo,
      structuredData,
      item,
      playlist: [],
      relations: [],
      relationsTopic: [],
      processing: false
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'description',
          name: 'description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: (this.metaSeo?.keyword || '')
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: (this.metaSeo?.image || '')
        },
        {
          hid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'twitter:url',
          property: 'twitter:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: (this.metaSeo?.image || '')
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath
        }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.structuredData
      }]
    }
  },
  computed: {
    ...mapState([
      'user',
      'isMobile'
    ])
  },
  mounted () {
    this.loadPlayList()
    this.getRelation()
    this.getRelationTopic()
  },
  methods: {
    async loadPlayList () {
      if (!this.user) {
        return
      }
      const {data} = await playlistResource.list({type: 'doc'})
      this.playlist = data
    },
    async getRelation () {
      const data = await relationResource.list({id: this.item.id})
      this.relations = data.data
    },
    async getRelationTopic () {
      const data = await relationTopicResource.list({file_id: this.item.id})
      this.relationsTopic = data.data
    },
    handerRedirect (redirect = []) {
      let data = null
      if (redirect.length) {
        data = {}
        redirect.forEach((ele) => {
          data[ele] = this.item[ele]
        })
      }
      this.$store.dispatch('setStoreRedirect', data)
      this.$router.push('/tai-lieu')
    },
    checkBeforeRedirect (item) {
      if (!this.user) {
        this.$router.push('/tai-lieu/' + item.slug)
        return
      }
      this.$helper.checkUserBuySub(this, item, '/tai-lieu/' + item.slug, 'Bạn cần mua gói thành viên để xem tài liệu này')
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-detail-inner-section {
  padding: 2.25rem 0 6rem;
  @media (max-width: 1366px) {
    padding: 2rem 0 4rem;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    padding: 2rem 0 4rem;
  }

  .breadcrumb {
    margin-bottom: .55rem;

    .breadcrumb-item {
      font-size: .875rem;
      line-height: 1.2;
      color: #888888;
    }
  }

  .doc-detail-content {
    line-height: 1.2;
    margin-bottom: 1.3rem;
    font-size: 1.5rem;
    @media (max-width: 1366px) {
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
      font-size: 1.25rem;
      margin-bottom: .75rem;
    }
  }

  .video-private {
    .notify {
      width: 290px;
      max-width: 100%;

      .action {
        button {
          background: #FFA216;
          border-radius: 18px;
        }
      }
    }
  }

  .library-preview-file {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: .5rem;
    max-height: 90vh;
    height: 45rem;
    @media (max-width: 1366px) {
    }
    @media (max-width: 1200px) {
    }
    @media (max-width: 992px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: 576px) {
      height: 30rem;
    }
  }

  #document-relation-right {
    max-height: 592px;

    .document-relation {
      box-shadow: 0px .25rem .75rem rgba(116, 116, 116, 0.34);
      border-radius: .5rem;
      padding: 1.5rem 1.25rem 1rem 1rem;
      @media (max-width: 1366px) {
        padding: 1rem;
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
        padding: 1rem 1rem .75rem .75rem;
      }

      &--title {
        line-height: 1.2;
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
        @media (max-width: 1366px) {
          font-size: 1rem;
          margin-bottom: .75rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }

      &--list {
        line-height: 1.375rem;
        @media (max-width: 1366px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
        }

        &--item {
          border-bottom: 1px solid #ececec;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    .icon-private {
      margin-left: 3.4rem;
      @media (max-width: 1366px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
      }
    }
  }
}
</style>
<style>
.lib-no-login {
  background: #fff;
  padding-bottom: 40px;
  border-radius: 8px;
}

.no-login-thumb {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.v-login {
  padding: 1rem;
  text-align: center;
}

.v-login-text {
  color: #F20C0C;
  font-size: 1.25rem;
  text-align: center;
}

.v-login-btn {
  margin-top: 1rem;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background: #4086FF;
  border-radius: 8px;
  padding: 9px 30px;
  cursor: pointer;
}
</style>
