<template>
  <div class="container ml-detail-video mb-5" v-if="item">
    <div class="breadcrumd-custom"><nuxt-link to="/khoa-hoc">Khóa học </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <nuxt-link :to="'/khoa-hoc/' + item.topic.slug">{{ item.topic.name }}</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">{{item.name}}</span></div>
    <ViewVideo :item="item" />
    <Relation title="Video cùng khóa học" class="video-related mt-3" type="all" :id="item.id"/>
    <Relation title="Video bài giảng liên quan" class="video-related mt-3" type="related" :id="item.id"/>
    <Comment class="video-comment mt-3" :object-id="item.id" />
  </div>
</template>

<script>
// import {mapState} from 'vuex'
import cookieParser from 'cookieparser'
import Resource from '~/common/api/resource'
import Comment from '~/components/mlearn/comment'
import Relation from '~/components/mlearn/video/relation.vue'
import ViewVideo from '~/components/mlearn/video/view.vue'
const videoResource = new Resource('library/file/getBySlug')
export default {
  components: {
    ViewVideo,
    Relation,
    Comment
  },
  layout: 'mlearn',
  // middleware ({ store, route, redirect, from }) {
  //   console.log('Middle ware : ', !store.state.authenticated)
  //   if (!store.state.authenticated) {
  //     // this.$bvModal.show('modal-login')
  //     // return redirect('/khoa-hoc?redirect=' + encodeURIComponent(route.fullPath))
  //   }
  // },
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    console.log('asyncData')
    const data = await videoResource.list({ slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Video không tồn tại', message: 'Video đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!data) {
      return error({ statusCode: 404, title: 'Video không tồn tại', message: 'Video đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const item = data.data
    return {
      item
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.ml-detail-video{
  .video-related{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding: 20px 24px 10px 24px;
    @media (max-width:1366px) {
      padding: 18px;
    }
    @media (max-width:576px) {
      padding: 16px;
    }
  }
  .video-comment{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding: 20px 24px 10px 24px;
    @media (max-width:1366px) {
      padding: 18px;
    }
    @media (max-width:576px) {
      padding: 16px;
    }
  }
}
</style>

<!--<template>-->
<!--  <section class="video-detail-inner-section" >-->
<!--    <h1>Chi tiết video</h1>-->
<!--    <div class="container lib-inner-container">-->
<!--      <div class="overflow-hidden display-html text-nowrap">-->
<!--        <ul class="bg-transparent breadcrumb p-0 flex-nowrap">-->
<!--          <li class="breadcrumb-item"><nuxt-link to="/khoa-hoc">Khóa học</nuxt-link></li>-->
<!--          <li class="breadcrumb-item"><span>{{detail.class_name}}</span></li>-->
<!--          <li class="breadcrumb-item"><span>{{detail.category_name}}</span></li>-->
<!--          <li class="breadcrumb-item" v-if="topic"><nuxt-link :to="'/khoa-hoc/'+topic.slug">{{topic.name}}</nuxt-link></li>-->
<!--          <li class="breadcrumb-item" aria-current="page">{{parentVideo ? parentVideo.name : detail.name}}</li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      <p class="color-darkgrey video-detail-content font-weight-bold" v-if="topic">{{topic.name}}</p>-->
<!--      <div class="statistical-content d-flex align-items-center">-->
<!--        <span class="d-flex align-items-center statistical-item" v-for="(teacher, ind) in detail.teachers" :key="ind">-->
<!--          <img src="~/assets/icons/library/user.svg" alt=""> Giáo viên: {{ teacher.name }}-->
<!--        </span>-->
<!--        <span class="d-flex align-items-center statistical-item" v-if="topic">-->
<!--          <img src="~/assets/icons/library/play-btn-icon.svg" alt="">{{ topic.number_video }} Video-->
<!--        </span>-->
<!--        <span v-if="topic" class="d-flex align-items-center statistical-item pointer" :class="{'is-active': topic.is_followed}" @click="topic.is_followed ? handleUnFollow() : handleFollow()">-->
<!--          <img v-if="!topic.is_followed" src="~/assets/icons/library/circle-plus.svg" alt="follow-icon-unactive" class="">-->
<!--          <img v-else src="~/assets/icons/library/circle-plus-blue.svg" alt="follow-icon-active" class="">-->
<!--          Theo dõi-->
<!--        </span>-->
<!--      </div>-->
<!--      <div class="row video-detail-row">-->
<!--        <div class="col-12 col-lg-8">-->
<!--          <div class="video-tab" ref="video-tab">-->
<!--            <div v-if="!user" class="ratio-custom video-private" style="&#45;&#45;aspect-ratio: 58.37%;">-->
<!--              <div>-->
<!--                <img :src="detail.thumbnail" alt="video-private" class="no-login-thumb">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-else-if="!detail.url" class="ratio-custom video-private" style="&#45;&#45;aspect-ratio: 58.37%;">-->
<!--              <div>-->
<!--                <img src="~/assets/images/library/video-private-control.png" alt="video-private" class="">-->
<!--                <div class="notify position-center text-white text-center">-->
<!--                  <img src="~/assets/icons/library/video-private-info.svg" alt="video-private-info" class="">-->
<!--                  <div class="mt-2 mb-3">Đăng ký gói cước MLearn để xem toàn bộ video, tài liệu, trắc nghiệm</div>-->
<!--                  <div class="action">-->
<!--                    <nuxt-link to="/goi-cuoc">-->
<!--                      <button class="border-0 py-2 px-4 text-white">Đăng ký ngay</button>-->
<!--                    </nuxt-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <PlayerVideo v-else :item="detail" />-->
<!--            <div v-if="!user" class="video-name">-->
<!--              <div class="mb-0 v-name">-->
<!--                {{ detail.name }}-->
<!--              </div>-->
<!--              <div class="mb-0 v-description" v-if="detail.meta_description">-->
<!--                {{ detail.meta_description }}-->
<!--              </div>-->
<!--              <div class="v-login">-->
<!--                <div class="v-login-text">Bạn sẽ không thể xem đầy đủ nội dung nếu chưa đăng nhập!</div>-->
<!--                <div class="v-login-btn" @click="$bvModal.show('modal-login')">Đăng nhập ngay</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-else class="video-name d-flex align-items-center">-->
<!--              <p class="mb-0 v-name font-weight-bold w-75">-->
<!--                {{ detail.name }}-->
<!--              </p>-->
<!--              <span class="ml-auto action d-flex align-items-center pointer" @click="showReport = true">-->
<!--                <img src="~/assets/icons/library/warning-icon.svg" alt="">-->
<!--                Báo lỗi-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="control-video-tabs" v-if="user">-->
<!--            <div class="tabs-controller">-->
<!--              <ul class="nav nav-pills  list-tabs" id="pills-tab" role="tablist">-->
<!--                <li class="nav-item mr-2 mr-lg-4" v-if="detail.description">-->
<!--                  <a class="nav-link" :class="{'active': tabActive === 1}" id="tab-controller-1" data-toggle="pill" href="#tab-content-1" role="tab" aria-controls="tab-content-1" aria-selected="true">Tóm tắt bài học</a>-->
<!--                </li>-->
<!--                <li class="nav-item mr-2 mr-lg-4" v-if="listExams.length">-->
<!--                  <a class="nav-link" :class="{'active': tabActive === 2}" id="tab-controller-2" data-toggle="pill" href="#tab-content-2" role="tab" aria-controls="tab-content-2" aria-selected="false">Bài tập tự luyện</a>-->
<!--                </li>-->
<!--                <li class="nav-item mr-2 mr-lg-4" v-if="listDocs.length">-->
<!--                  <a class="nav-link" :class="{'active': tabActive === 3}" id="tab-controller-3" data-toggle="pill" href="#tab-content-3" role="tab" aria-controls="tab-content-3" aria-selected="false">Tài liệu</a>-->
<!--                </li>-->
<!--                <li class="nav-item" v-if="user">-->
<!--                  <a class="nav-link" :class="{'active': tabActive === 4}" id="tab-controller-4" data-toggle="pill" href="#tab-content-4" role="tab" aria-controls="tab-content-4" aria-selected="false">Thảo luận</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--              <div class="tab-content" id="pills-tabContent">-->
<!--                <div v-if="detail.description" class="tab-pane fade" :class="{'show active': tabActive === 1}" id="tab-content-1" role="tabpanel" aria-labelledby="tab-controller-1">-->
<!--                  <vue-mathjax :formula="detail.description" :safe="false"></vue-mathjax>-->
<!--                </div>-->
<!--                <div v-if="listExams.length" class="tab-pane fade" :class="{'show active': tabActive === 2}" id="tab-content-2" role="tabpanel" aria-labelledby="tab-controller-2">-->
<!--                  <div class="extra-block d-flex align-items-center color-darkgrey mb-2" v-for="(it, ind) in listExams" :key="ind">-->
<!--                    <span class="type-n-text d-flex align-items-center pointer" @click="viewExam(it)">-->
<!--                      <img src="~/assets/icons/library/write-grey.svg" alt="" class="type-ico"> {{ it.name }}-->
<!--                    </span>-->
<!--                    <span class="mcq-counter d-flex ml-auto" v-if="it.is_complete">-->
<!--                      <span class="sc">{{it.number_correct || 0}}</span>-->
<!--                      <span class="total">/{{it.number_question}}</span>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="listDocs.length" class="tab-pane fade" :class="{'show active': tabActive === 3}" id="tab-content-3" role="tabpanel" aria-labelledby="tab-controller-3">-->
<!--                  <div class="extra-block d-flex align-items-center color-darkgrey mb-2" v-for="(it, ind) in listDocs" :key="ind">-->
<!--                    <span class="type-n-text d-flex align-items-center pointer" @click="viewDocument(it, 'view')">-->
<!--                      <img src="~/assets/icons/library/pdf-grey.svg" alt="" class="type-ico" />{{ it.name }}-->
<!--                    </span>-->
<!--                    <span class="download-ico ml-auto pointer" @click="viewDocument(it, 'download')">-->
<!--                      <img src="~/assets/icons/library/download-grey.svg" alt="">-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div v-if="user" class="tab-pane comment fade" :class="{'show active': tabActive === 4}" id="tab-content-4" role="tabpanel" aria-labelledby="tab-controller-4">-->
<!--                  <Comment :object-id="detail.id" />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 col-lg-4 mt-3 mt-lg-0">-->
<!--          <div class="sticky-ls">-->
<!--            <div class="list-unit bg-white scrollbar mb-3" :style="{height: heightFrame}" v-if="topic">-->
<!--              <Collections :collections="topic.collections" type="video" :parent-id="parentVideo ? parentVideo.id : ''" :video-id="detail.id" />-->
<!--            </div>-->
<!--            <div v-if="group && 1===2" class="mb-3 btn-action btn-join d-flex align-items-center pointer justify-content-center w-100 font-weight-bold" :class="{'bg-success': group.is_joined}">-->
<!--              <img src="~/assets/icons/library/group-users.svg" alt=""> {{ group.is_joined ? 'Đã tham gia nhóm' : 'Tham gia học nhóm' }}-->
<!--            </div>-->
<!--            <Rating v-if="topic" :detail="topic" type="video" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <b-modal id="modal-report-video" size="sm" v-model="showReport" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>-->
<!--      <img class="close" src="/icon/library/close.svg" alt="close" @click="showReport = false"/>-->
<!--      <div class="form-report">-->
<!--        <div class="header-report">-->
<!--          <img src="~/assets/images/icons/library/report.png" alt="report" class="report" />-->
<!--          <h2>Báo lỗi Video</h2>-->
<!--        </div>-->
<!--        <div class="line"></div>-->
<!--        <div class="list-reason">-->
<!--          <div class="custom-control custom-radio">-->
<!--            <input type="radio" class="custom-control-input" id="reason-1" name="reason" :value="1" v-model="error">-->
<!--            <label class="custom-control-label" for="reason-1">Lỗi không xem được video</label>-->
<!--          </div>-->
<!--          <div class="custom-control custom-radio">-->
<!--            <input type="radio" class="custom-control-input" id="reason-2" name="reason" :value="2" v-model="error">-->
<!--            <label class="custom-control-label" for="reason-2">Lỗi khác</label>-->
<!--          </div>-->
<!--          <textarea class="reason-text resize-none" v-model="reasonText" placeholder="Vui lòng mô tả lại lỗi bạn gặp" v-if="error === 2"></textarea>-->
<!--        </div>-->
<!--        <div class="action">-->
<!--          <button @click="reportVideo">Gửi báo lỗi</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-modal>-->
<!--    <b-modal id="modal-view-document" no-close-on-backdrop centered hide-footer hide-header body-class="p-3 p-lg-4 position-relative" size="lg" content-class="rounded-sm" v-model="showModalViewDocument">-->
<!--      <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalViewDocument = false"/>-->
<!--      <div v-if="documentView">-->
<!--        <div class="font-weight-bold limit-line-2">{{documentView.name}}</div>-->
<!--        <div class="library-preview-file mt-2">-->
<!--          <ViewPdf :link="documentView.url" height="100%"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-modal>-->
<!--    <b-modal id="modal-view-exam" centered hide-footer hide-header body-class="p-0 position-relative" size="xl" content-class="rounded-sm" v-model="showModalViewExam">-->
<!--      <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalViewExam = false"/>-->
<!--      <div v-if="examView">-->
<!--        <TakeExamComponent :item="examView" @success="showModalViewExam = false" />-->
<!--      </div>-->
<!--    </b-modal>-->
<!--    <b-modal id="modal-preview-exam" no-close-on-backdrop centered hide-footer hide-header body-class="p-4 p-lg-5 position-relative" size="lg" content-class="rounded-sm" v-model="showModalPreviewExam">-->
<!--      <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalPreviewExam = false"/>-->
<!--      <div v-if="examView">-->
<!--        <div class="h3 font-weight-bold text-break text-body mb-4">{{examView.name}}</div>-->
<!--        <div class="mb-3 font-weight-bold">-->
<!--          Thời gian làm bài: <span class="ml-1 text-danger">{{ examView.time }}phút</span>-->
<!--          <span class="mx-4">|</span>-->
<!--          Số câu: <span class="ml-1 text-danger">{{examView.number_question}}</span>-->
<!--        </div>-->
<!--        <div v-if="examView.description" v-html="examView.description"></div>-->
<!--        <div class="mt-5" @click="startExam">-->
<!--          <button class="action px-4 py-2 text-white bg-primary border-0">Làm bài</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </b-modal>-->
<!--  </section>-->
<!--</template>-->

<!--<script>-->
<!--import TakeExamComponent from '~/components/library-v2/quiz/take.vue'-->
<!--import Collections from '~/components/library-v2/topic/collection.vue'-->
<!--import Rating from '~/components/library-v2/topic/rating.vue'-->
<!--import PlayerVideo from '~/components/player/video.vue'-->
<!--import Comment from '~/components/library-v2/commentv2'-->
<!--import Resource from '~/common/api/resource'-->
<!--import { checkAllow } from '~/common/api/check-register'-->
<!--const videoResource = new Resource('library/file/getBySlug')-->
<!--const topicSlugResource = new Resource('library/topic_v2/getBySlug')-->
<!--const followResource = new Resource('library/topic/follow')-->
<!--const unFollowResource = new Resource('library/topic/unFollow')-->
<!--const reportVideoResource = new Resource('library/video/report')-->
<!--const groupResource = new Resource('library/topic/group')-->
<!--const attachDocumentResource = new Resource('library/attach-document')-->
<!--const fileResource = new Resource('library/file/getBySlug')-->
<!--const examStartResource = new Resource('library/exam/start')-->
<!--export default {-->
<!--  components: {-->
<!--    Collections,-->
<!--    Rating,-->
<!--    PlayerVideo,-->
<!--    Comment,-->
<!--    TakeExamComponent-->
<!--  },-->
<!--  layout: 'thu-vien',-->
<!--  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {-->
<!--    const slug = params.slug-->
<!--    const token = store.state.token-->
<!--    const { data: detail } = await videoResource.list({ slug }, token)-->
<!--      .catch((err) => {-->
<!--        if (err.response.data.status === 404) {-->
<!--          return redirect('/')-->
<!--        }-->
<!--        return error({ statusCode: err.response.data.status })-->
<!--      })-->
<!--    console.log('detail Video :', detail)-->
<!--    if (!detail) {-->
<!--      return redirect('/')-->
<!--    }-->
<!--    const metaSeo = {-->
<!--      title: detail.meta_title || detail.name,-->
<!--      image: detail.meta_image || detail.thumbnail,-->
<!--      description: detail.meta_description || detail.name,-->
<!--      keyword: detail.meta_keyword || ''-->
<!--    }-->
<!--    const structuredData = {}-->
<!--    return {-->
<!--      detail,-->
<!--      topic: null,-->
<!--      metaSeo,-->
<!--      structuredData-->
<!--    }-->
<!--  },-->
<!--  data () {-->
<!--    return {-->
<!--      showReport: false,-->
<!--      error: 1,-->
<!--      reasonText: '',-->
<!--      group: null,-->
<!--      listDocs: [],-->
<!--      listExams: [],-->
<!--      showModalViewDocument: false,-->
<!--      documentView: null,-->
<!--      showModalPreviewExam: false,-->
<!--      showModalViewExam: false,-->
<!--      examView: null,-->
<!--      parentVideo: null,-->
<!--      heightFrame: '640px'-->
<!--    }-->
<!--  },-->
<!--  head () {-->
<!--    if (!this.metaSeo) {-->
<!--      return {}-->
<!--    }-->
<!--    return {-->
<!--      title: (this.metaSeo?.title || ''),-->
<!--      meta: [-->
<!--        { hid: 'title', name: 'title', content: (this.metaSeo?.title || '') },-->
<!--        { hid: 'description', name: 'description', content: (this.metaSeo?.description || '') },-->
<!--        { hid: 'keywords', name: 'keywords', content: (this.metaSeo?.keyword || '') },-->
<!--        { hid: 'og:type', property: 'og:type', content: 'website' },-->
<!--        { hid: 'og:title', property: 'og:title', content: (this.metaSeo?.title || '') },-->
<!--        { hid: 'og:url', property: 'og:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },-->
<!--        { hid: 'og:description', property: 'og:description', content: (this.metaSeo?.description || '') },-->
<!--        { hid: 'og:image', property: 'og:image', content: (this.metaSeo?.image || '') },-->
<!--        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },-->
<!--        { hid: 'twitter:title', property: 'twitter:title', content: (this.metaSeo?.title || '') },-->
<!--        { hid: 'twitter:url', property: 'twitter:url', content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath) },-->
<!--        { hid: 'twitter:description', property: 'twitter:description', content: (this.metaSeo?.description || '') },-->
<!--        { hid: 'twitter:image', property: 'twitter:image', content: (this.metaSeo?.image || '') }-->
<!--      ],-->
<!--      link: [-->
<!--        { rel: 'canonical', href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath }-->
<!--      ],-->
<!--      script: [{ type: 'application/ld+json', json: this.structuredData }]-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    token () {-->
<!--      return this.$store.state.token-->
<!--    },-->
<!--    tabActive () {-->
<!--      if (this.detail.description) {-->
<!--        return 1-->
<!--      } else if (this.listExams.length) {-->
<!--        return 2-->
<!--      } else if (this.listDocs.length) {-->
<!--        return 3-->
<!--      } else {-->
<!--        return 4-->
<!--      }-->
<!--    },-->
<!--    user () {-->
<!--      return this.$store.state.user-->
<!--    }-->
<!--  },-->
<!--  async created () {-->
<!--    await this.loadTopic()-->
<!--    this.loadGroup()-->
<!--    this.loadDocument('PDF')-->
<!--    this.loadDocument('EXAM')-->
<!--    this.getParentId()-->
<!--  },-->
<!--  mounted () {-->
<!--    const element = this.$refs['video-tab'].clientHeight-->
<!--    this.heightFrame = `${element}px`-->
<!--  },-->
<!--  methods: {-->
<!--    async loadTopic () {-->
<!--      const { data: topic } = await topicSlugResource.list({ slug: this.detail.topic.slug }, this.token)-->
<!--      this.topic = topic-->
<!--    },-->
<!--    handleFollow () {-->
<!--      followResource.store({ topic_id: this.topic.id })-->
<!--        .then((res) => {-->
<!--          this.topic.is_followed = 1-->
<!--        })-->
<!--        .catch((err) => {-->
<!--          console.log(err)-->
<!--        })-->
<!--    },-->
<!--    handleUnFollow () {-->
<!--      unFollowResource.store({ topic_id: this.topic.id })-->
<!--        .then((res) => {-->
<!--          this.topic.is_followed = 0-->
<!--        })-->
<!--        .catch((err) => {-->
<!--          console.log(err)-->
<!--        })-->
<!--    },-->
<!--    reportVideo () {-->
<!--      this.$notify.closeAll()-->
<!--      if (this.processing) { return }-->
<!--      if (this.error !== 1) {-->
<!--        if (!this.reasonText) {-->
<!--          this.$notify({-->
<!--            type: 'error',-->
<!--            message: 'Chưa nhập lý do'-->
<!--          })-->
<!--          return-->
<!--        }-->
<!--        if (this.reasonText.length > 255) {-->
<!--          this.$notify({-->
<!--            type: 'error',-->
<!--            message: 'Nội dung không dài quá 255 ký tự'-->
<!--          })-->
<!--          return-->
<!--        }-->
<!--      }-->
<!--      const text = this.error === 1 ? 'Lỗi không xem được video' : (this.reasonText || 'Lỗi khác')-->
<!--      const formData = {-->
<!--        video_id: this.detail.id,-->
<!--        reason: text-->
<!--      }-->
<!--      this.processing = true-->
<!--      reportVideoResource.store(formData)-->
<!--        .then((res) => {-->
<!--          this.showReport = false-->
<!--          this.$notify({-->
<!--            type: 'success',-->
<!--            message: 'Gửi báo lỗi thành công'-->
<!--          })-->
<!--        })-->
<!--        .finally(() => {-->
<!--          this.processing = false-->
<!--        })-->
<!--    },-->
<!--    async loadGroup () {-->
<!--      const { data } = await groupResource.list({ topic_id: this.topic.id }, this.token)-->
<!--      this.group = Array.isArray(data) ? null : data-->
<!--    },-->
<!--    async loadDocument (type = 'PDF') {-->
<!--      const { data } = await attachDocumentResource.list({ type, object_id: this.detail.id })-->
<!--      if (type === 'PDF') {-->
<!--        this.listDocs = data-->
<!--      } else {-->
<!--        this.listExams = data-->
<!--      }-->
<!--    },-->
<!--    async viewExam (item) {-->
<!--      if (!this.user) {-->
<!--        this.$router.push('/trac-nghiem/' + item.slug)-->
<!--        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/' + item.slug-->
<!--        // window.open(url, '_blank').focus()-->
<!--      }-->
<!--      if (item.is_updating) {-->
<!--        let message = 'Video'-->
<!--        if (item.type === 'PDF') {-->
<!--          message = 'Tài liệu'-->
<!--        } else if (item.type === 'EXAM') {-->
<!--          message = 'Bài tập'-->
<!--        }-->
<!--        this.$notify.warning(message + ' đang được cập nhật')-->
<!--        return-->
<!--      }-->
<!--      if (item.is_complete) {-->
<!--        this.$router.push('/trac-nghiem/ket-qua/' + item.slug)-->
<!--        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/ket-qua/' + item.slug-->
<!--        // window.open(url, '_blank').focus()-->
<!--        return-->
<!--      }-->
<!--      const { data } = await checkAllow({-->
<!--        class_id: item.class_id,-->
<!--        type: 'EXAM',-->
<!--        object_id: item.id-->
<!--      })-->
<!--      if (data.quiz === 0) {-->
<!--        this.$store.dispatch('showStopModal', {-->
<!--          type: 'EXAM',-->
<!--          classId: item.class_id,-->
<!--          dataFree: data.quiz_free-->
<!--        })-->
<!--      } else {-->
<!--        this.$router.push('/trac-nghiem/' + item.slug)-->
<!--        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/' + item.slug-->
<!--        // window.open(url, '_blank').focus()-->
<!--      }-->
<!--    },-->
<!--    startExam () {-->
<!--      examStartResource.list({ exam_slug: this.examView.slug })-->
<!--        .then(({ data }) => {-->
<!--          this.examView = data-->
<!--          this.showModalPreviewExam = false-->
<!--          this.showModalViewExam = true-->
<!--        })-->
<!--        .catch((err) => {-->
<!--          this.$notify.error(err.response.data.message)-->
<!--        })-->
<!--    },-->
<!--    async viewDocument (item, type = 'view') {-->
<!--      if (!this.user) {-->
<!--        this.$router.push('/tai-lieu/' + item.slug)-->
<!--        // const url = process.env.NUXT_ENV_WEB_URL + '/tai-lieu/' + item.slug-->
<!--        // window.open(url, '_blank').focus()-->
<!--        return-->
<!--      }-->
<!--      let message = 'Video'-->
<!--      if (item.type === 'PDF') {-->
<!--        message = 'Tài liệu'-->
<!--      } else if (item.type === 'EXAM') {-->
<!--        message = 'Bài tập'-->
<!--      }-->
<!--      if (item.is_updating) {-->
<!--        this.$notify.warning(message + ' đang được cập nhật')-->
<!--        return-->
<!--      }-->
<!--      const { data } = await checkAllow({-->
<!--        class_id: item.class_id,-->
<!--        type: 'DOCUMENT',-->
<!--        object_id: item.id-->
<!--      })-->
<!--      if (data.document === 0) {-->
<!--        this.$store.dispatch('showStopModal', {-->
<!--          type: 'PDF',-->
<!--          classId: item.class_id,-->
<!--          dataFree: data.quiz_free-->
<!--        })-->
<!--      } else {-->
<!--        fileResource.list({ slug: item.slug })-->
<!--          .then(({ data }) => {-->
<!--            if (type === 'view') {-->
<!--              this.setUrlDocument(data)-->
<!--            } else {-->
<!--              this.downloadFile(data)-->
<!--            }-->
<!--          })-->
<!--          .catch((err) => {-->
<!--            this.$notify.error(err.response.data.message)-->
<!--          })-->
<!--      }-->
<!--    },-->
<!--    setUrlDocument (document) {-->
<!--      // this.showModalViewDocument = true-->
<!--      // this.documentView = document-->
<!--      // const url = process.env.NUXT_ENV_WEB_URL + '/tai-lieu/' + document.slug-->
<!--      // window.open(url, '_blank').focus()-->
<!--      this.$router.push('/tai-lieu/' + document.slug)-->
<!--    },-->
<!--    downloadFile (item) {-->
<!--      const element = document.createElement('a')-->
<!--      element.setAttribute('href', item.url)-->
<!--      element.setAttribute('target', '_blank')-->
<!--      element.style.display = 'none'-->
<!--      document.body.appendChild(element)-->
<!--      element.click()-->
<!--      document.body.removeChild(element)-->
<!--    },-->
<!--    getParentId () {-->
<!--      this.topic?.collections.forEach((item) => {-->
<!--        if (item.type === 'VIDEO') {-->
<!--          const check = item.items.find(it => it.id === this.detail.id)-->
<!--          if (check) {-->
<!--            this.parentVideo = item-->
<!--          }-->
<!--        }-->
<!--      })-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.video-detail-inner-section {-->
<!--  padding: 2.25rem 0 6rem;-->
<!--  @media (max-width: 1366px) {}-->
<!--  @media (max-width: 1200px) {}-->
<!--  @media (max-width: 992px) {}-->
<!--  @media (max-width: 768px) {}-->
<!--  @media (max-width: 576px) {-->
<!--    padding: 1.5rem 0 3rem;-->
<!--  }-->
<!--  .breadcrumb {-->
<!--    .breadcrumb-item {-->
<!--      font-size: .875rem;-->
<!--      line-height: 1.2;-->
<!--      color: #777777;-->
<!--    }-->
<!--  }-->
<!--  .sticky-ls {-->
<!--    height: fit-content;-->
<!--    .list-unit{-->
<!--      border-radius: .5rem;-->
<!--      overflow-y: scroll;-->
<!--      overflow-x: hidden;-->
<!--      padding: 0 1rem;-->
<!--      max-height: 40rem;-->
<!--      @media (max-width: 1366px) {}-->
<!--      @media (max-width: 1200px) {}-->
<!--      @media (max-width: 992px) {}-->
<!--      @media (max-width: 768px) {}-->
<!--      @media (max-width: 576px) {-->
<!--        padding: 0 .75rem;-->
<!--        max-height: 30rem;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--  .video-detail-content {-->
<!--    line-height: 1.2;-->
<!--    font-size: 1.5rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      font-size: 1.25rem;-->
<!--    }-->
<!--  }-->
<!--  .video-detail-row {-->
<!--    margin-top: 1rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      margin-top: .75rem;-->
<!--    }-->
<!--  }-->
<!--  .statistical-content {-->
<!--    gap: 2.375rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      gap: 1.5rem;-->
<!--    }-->
<!--    .statistical-item {-->
<!--      gap: .375rem;-->
<!--      color: #888888;-->
<!--      font-size: 1rem;-->
<!--      line-height: 1.2;-->
<!--      &.is-active {-->
<!--        color: #4086ff;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--  .video-tab {-->
<!--    background: #ffffff;-->
<!--    box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.07);-->
<!--    border-radius: .5rem;-->
<!--    .video-private{-->
<!--      .notify {-->
<!--        width: 290px;-->
<!--        max-width: 100%;-->
<!--        .action{-->
<!--          button{-->
<!--            background: #FFA216;-->
<!--            border-radius: 18px;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--    .video-name {-->
<!--      padding: 1.5rem;-->
<!--      @media (max-width: 1366px) {}-->
<!--      @media (max-width: 1200px) {}-->
<!--      @media (max-width: 992px) {}-->
<!--      @media (max-width: 768px) {}-->
<!--      @media (max-width: 576px) {-->
<!--        padding: 1rem;-->
<!--      }-->
<!--      .v-name {-->
<!--        line-height: 1.5;-->
<!--        font-size: 1.25rem;-->
<!--        @media (max-width: 1366px) {}-->
<!--        @media (max-width: 1200px) {}-->
<!--        @media (max-width: 992px) {}-->
<!--        @media (max-width: 768px) {}-->
<!--        @media (max-width: 576px) {-->
<!--          font-size: 1rem;-->
<!--        }-->
<!--      }-->
<!--      .action {-->
<!--        gap: .375rem;-->
<!--        font-weight: 400;-->
<!--        font-size: 1.125rem;-->
<!--        line-height: 1.2;-->
<!--        text-align: right;-->
<!--        color: #6b6d70;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--  .control-video-tabs {-->
<!--    background: #ffffff;-->
<!--    box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.07);-->
<!--    border-radius: .5rem;-->
<!--    margin-top: 1rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      margin-top: .75rem;-->
<!--    }-->
<!--    .list-tabs {-->
<!--      padding: 0 3rem;-->
<!--      @media (max-width: 1366px) {}-->
<!--      @media (max-width: 1200px) {}-->
<!--      @media (max-width: 992px) {}-->
<!--      @media (max-width: 768px) {}-->
<!--      @media (max-width: 576px) {-->
<!--        padding: 0 1rem;-->
<!--        overflow: auto;-->
<!--        flex-wrap: nowrap;-->
<!--        white-space: nowrap;-->
<!--      }-->
<!--      border-bottom: 1px solid #eeeeee;-->
<!--      .nav-item {-->
<!--        padding-top: .3rem;-->
<!--        .nav-link {-->
<!--          background: transparent;-->
<!--          font-weight: 400;-->
<!--          font-size: 1.125rem;-->
<!--          line-height: 1.2;-->
<!--          border-radius: 0;-->
<!--          color: #9d9d9d;-->
<!--          padding: .875rem;-->
<!--          @media (max-width: 1366px) {}-->
<!--          @media (max-width: 1200px) {}-->
<!--          @media (max-width: 992px) {}-->
<!--          @media (max-width: 768px) {}-->
<!--          @media (max-width: 576px) {-->
<!--            padding: .5rem;-->
<!--          }-->
<!--          &.active {-->
<!--            color: #4086ff;-->
<!--            border-bottom: 2px solid #4086ff;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--    .tab-content {-->
<!--      color: #262f3e;-->
<!--      .tab-pane {-->
<!--        padding: 2rem;-->
<!--        @media (max-width: 1366px) {}-->
<!--        @media (max-width: 1200px) {}-->
<!--        @media (max-width: 992px) {}-->
<!--        @media (max-width: 768px) {}-->
<!--        @media (max-width: 576px) {-->
<!--          padding: 1rem;-->
<!--        }-->
<!--        &.comment {-->
<!--          padding: 1.25rem 2rem 2rem;-->
<!--          @media (max-width: 1366px) {}-->
<!--          @media (max-width: 1200px) {}-->
<!--          @media (max-width: 992px) {}-->
<!--          @media (max-width: 768px) {}-->
<!--          @media (max-width: 576px) {-->
<!--            padding: 1rem 1.25rem 1.25rem;-->
<!--          }-->
<!--        }-->
<!--        .tab-title {-->
<!--          font-weight: 500;-->
<!--          line-height: 1.2;-->
<!--          color: #262f3e;-->
<!--          font-size: 1.125rem;-->
<!--          @media (max-width: 1366px) {}-->
<!--          @media (max-width: 1200px) {}-->
<!--          @media (max-width: 992px) {}-->
<!--          @media (max-width: 768px) {}-->
<!--          @media (max-width: 576px) {-->
<!--            font-size: 1rem;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--      .extra-block {-->
<!--        background: #f7f7f7;-->
<!--        border-radius: .5rem;-->
<!--        padding: .5rem 1rem;-->
<!--        @media (max-width: 1366px) {}-->
<!--        @media (max-width: 1200px) {}-->
<!--        @media (max-width: 992px) {}-->
<!--        @media (max-width: 768px) {}-->
<!--        @media (max-width: 576px) {-->
<!--          padding: .5rem;-->
<!--        }-->
<!--        .type-n-text {-->
<!--          gap: .5rem;-->
<!--        }-->
<!--        .mcq-counter {-->
<!--          font-weight: 500;-->
<!--          font-size: 1.125rem;-->
<!--          @media (max-width: 1366px) {}-->
<!--          @media (max-width: 1200px) {}-->
<!--          @media (max-width: 992px) {}-->
<!--          @media (max-width: 768px) {}-->
<!--          @media (max-width: 576px) {-->
<!--            font-size: 1rem;-->
<!--          }-->
<!--          .sc {-->
<!--            color: #7bbf6a;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--  .btn-join {-->
<!--    gap: .5rem;-->
<!--    background: #4086ff;-->
<!--    border-radius: .5rem;-->
<!--    color: #ffffff;-->
<!--    font-size: 1.125rem;-->
<!--    padding: .625rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      font-size: 1rem;-->
<!--      padding: .5rem;-->
<!--    }-->
<!--  }-->
<!--  .rating-block {-->
<!--    background: #ffffff;-->
<!--    box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.07);-->
<!--    border-radius: .5rem;-->
<!--    padding: 1rem;-->
<!--    @media (max-width: 1366px) {}-->
<!--    @media (max-width: 1200px) {}-->
<!--    @media (max-width: 992px) {}-->
<!--    @media (max-width: 768px) {}-->
<!--    @media (max-width: 576px) {-->
<!--      padding: .75rem;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
<!--<style lang="scss">-->
<!--#modal-report-video{-->
<!--  .modal-dialog{-->
<!--    max-width: 21rem;-->
<!--    .modal-content{-->
<!--      border-radius: .875rem;-->
<!--    }-->
<!--    .modal-body{-->
<!--      position: relative;-->
<!--      padding:1.4rem;-->
<!--      @media (max-width: 1366px) {}-->
<!--      @media (max-width: 1200px) {}-->
<!--      @media (max-width: 992px) {}-->
<!--      @media (max-width: 768px) {}-->
<!--      @media (max-width: 576px) {-->
<!--        padding:1rem;-->
<!--      }-->
<!--      .close{-->
<!--        position: absolute;-->
<!--        right: .75rem;-->
<!--        top: .75rem;-->
<!--        cursor: pointer;-->
<!--      }-->
<!--      .form-report{-->
<!--        .header-report{-->
<!--          text-align: center;-->
<!--          h2 {-->
<!--            font-weight: bold;-->
<!--            line-height: 1.2;-->
<!--            color: #333333;-->
<!--            font-size: 1.5rem;-->
<!--            margin:1.25rem 0 1.125rem;-->
<!--            @media (max-width: 1366px) {}-->
<!--            @media (max-width: 1200px) {}-->
<!--            @media (max-width: 992px) {}-->
<!--            @media (max-width: 768px) {}-->
<!--            @media (max-width: 576px) {-->
<!--              font-size: 1.25rem;-->
<!--              margin:1rem 0;-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--        .line{-->
<!--          border-top: 1px solid #EEEEEE;-->
<!--        }-->
<!--        .list-reason{-->
<!--          > div{-->
<!--            padding-top: 1rem;-->
<!--            @media (max-width: 1366px) {}-->
<!--            @media (max-width: 1200px) {}-->
<!--            @media (max-width: 992px) {}-->
<!--            @media (max-width: 768px) {}-->
<!--            @media (max-width: 576px) {-->
<!--              padding-top: .75rem;-->
<!--            }-->
<!--            input {-->
<!--              cursor: pointer;-->
<!--              margin-right: 1.25rem;-->
<!--              @media (max-width: 1366px) {}-->
<!--              @media (max-width: 1200px) {}-->
<!--              @media (max-width: 992px) {}-->
<!--              @media (max-width: 768px) {}-->
<!--              @media (max-width: 576px) {-->
<!--                margin-right: 1rem;-->
<!--              }-->
<!--            }-->
<!--            label{-->
<!--              cursor: pointer;-->
<!--              font-style: normal;-->
<!--              font-weight: normal;-->
<!--              line-height: 1.2;-->
<!--              color: #333333;-->
<!--              padding-top: 3px;-->
<!--              font-size: 1.125rem;-->
<!--              padding-left: 1.25rem;-->
<!--              &::before, &::after{-->
<!--                width: 1.25rem;-->
<!--                height: 1.25rem;-->
<!--                outline: none !important;-->
<!--                box-shadow: none !important;-->
<!--                border-color:var(&#45;&#45;warning);-->
<!--              }-->
<!--            }-->
<!--            .custom-control-input:checked {-->
<!--              ~ .custom-control-label::before {-->
<!--                background: #fff;-->
<!--              }-->
<!--              ~ .custom-control-label::after {-->
<!--                background-image: url('~/assets/images/icons/library/circle-radio.png');-->
<!--              }-->
<!--            }-->
<!--          }-->
<!--          .reason-text{-->
<!--            margin-top: .625rem;-->
<!--            border: 1px solid #E9E9E9;-->
<!--            border-radius: .75rem;-->
<!--            padding:.625rem;-->
<!--            font-size: .75rem;-->
<!--            line-height: 1.2;-->
<!--            width:100%;-->
<!--            height:6.25rem;-->
<!--            &::placeholder{-->
<!--              color: #888888;-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--        .action{-->
<!--          text-align: center;-->
<!--          padding-top: .875rem;-->
<!--          button{-->
<!--            background:var(&#45;&#45;primary);-->
<!--            border-radius: 1.25rem;-->
<!--            padding:.625rem 1.25rem;-->
<!--            font-weight: bold;-->
<!--            font-size: 1rem;-->
<!--            line-height: 1.2;-->
<!--            color: #FFFFFF;-->
<!--            border: none;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--#modal-view-document{-->
<!--  &___BV_modal_outer_{-->
<!--    z-index: 9100 !important;-->
<!--  }-->
<!--  .modal-body{-->
<!--    .close{-->
<!--      right: .75rem;-->
<!--      top:.75rem;-->
<!--      z-index: 8;-->
<!--    }-->
<!--    .library-preview-file{-->
<!--      height: 37.5rem;-->
<!--      max-height: 37.5rem;-->
<!--      @media (max-width: 1366px) {}-->
<!--      @media (max-width: 1200px) {}-->
<!--      @media (max-width: 992px) {}-->
<!--      @media (max-width: 768px) {}-->
<!--      @media (max-width: 576px) {-->
<!--        height: 18.75rem;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--#modal-view-exam {-->
<!--  &___BV_modal_outer_{-->
<!--    z-index: 9100 !important;-->
<!--  }-->
<!--  .modal-body{-->
<!--    .close{-->
<!--      right: .95rem;-->
<!--      top:.95rem;-->
<!--      z-index: 8;-->
<!--      filter: brightness(0) invert(1);-->
<!--    }-->
<!--  }-->
<!--}-->
<!--#modal-preview-exam {-->
<!--  &___BV_modal_outer_{-->
<!--    z-index: 9100 !important;-->
<!--  }-->
<!--  .modal-body{-->
<!--    .close{-->
<!--      right: .95rem;-->
<!--      top:.95rem;-->
<!--      z-index: 8;-->
<!--    }-->
<!--    .action{-->
<!--      border-radius: 1.25rem;-->
<!--    }-->
<!--  }-->
<!--}-->
.no-login-thumb {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.v-login {
  padding: 1rem;
  text-align: center;
  .v-login-text {
    color: #F20C0C;
    font-size: 1.25rem;
    text-align: center;
  }
  .v-login-btn {
    margin-top: 1rem;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    background: #4086FF;
    border-radius: 8px;
    padding: 9px 30px;
    cursor: pointer;
  }
<!--}-->
<!--</style>-->
